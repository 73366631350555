.thumbnail-class {
  max-width: 70px;
}

/* required for checkout page, should fix fixed of depending on antd */
#checkout-form .ant-form-item.ant-row {
  display: block !important;
}

.ant-popover {
  max-width: 340px;
}

.choose-country-popover.ant-popover {
  max-width: none;
  height: 480px;
}

.ant-popover-inner {
  border-radius: 16px !important;
}

.ant-popover-message:not(.choose-country-popover) {
  /* SNP Primary/Nice Navy (80%) - 345276 */
  color: #345276;
  margin-bottom: 24px;
  line-height: 150%;
  padding: 0;
}

.ant-popover-message-title {
  padding-left: 0 !important;
}

.ant-popover-message > .anticon {
  display: none;
}

.ant-popover-buttons .ant-btn {
  min-width: 92px;
  height: 40px;
  border-radius: 4px !important;
}

.ant-popover-buttons .ant-btn-default > span {
  /* SNP Primary/Nice Navy (80%) - 345276 */
  color: #345276;
}

.ant-popover-buttons .ant-btn-default:hover {
  background: #fff;
  /* SNP Primary/Indigo-3077C9 */
  border: 1px solid #3077C9 !important;
}

.ant-popover-buttons .ant-btn-default:hover > span {
  /* SNP Primary/Nice Navy-012754 */
  color: #012754;
}

.ant-btn-default[disabled] {
  /* SNP Primary/Nice Navy (5%) - F2F4F6 */
  background: #F2F4F6 !important;
  border-color: #F2F4F6 !important;
  cursor: not-allowed;
}

.ant-btn-default[disabled] > span {
  /* SNP Primary/Nice Navy (35%) - A6B3C3 */
  color: #A6B3C3 !important;
}

button:disabled {
  /* SNP Primary/Nice Navy (5%) - F2F4F6 */
  background: #F2F4F6 !important;
  border-color: #F2F4F6 !important;
  color: #A6B3C3 !important;
  cursor: not-allowed;
}

.ant-popover-buttons .ant-btn-primary {
  /* SNP Primary/Indigo-3077C9 */
  background: #3077C9 !important;
}

.ant-popover-buttons .ant-btn-primary:hover {
  /* SNP Primary/Dark Indigo - 2864A8 */
  background: #2864A8 !important;
}

.ant-popover-inner-content {
  padding: 24px 16px 16px 24px !important;
}

.choose-country-popover .ant-popover-inner-content {
  padding: 0 !important;
  width: 361px;
  max-height: 480px;
  display: flex;
}

.ant-modal-mask {
  background: rgba(0, 0, 0, 0.5) !important;
  backdrop-filter: blur(3px);
}

@keyframes slideFromBottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  100% {
    transform: translateY(0);
    opacity: 1;

  }
}

@keyframes slideToBottom {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}

.choose-country-modal-wrap.ant-modal-wrap {
  opacity: 0;
  top: 48px;
  transform: translateY(100%);
}

.choose-country-modal-wrap.ant-modal-wrap.show-modal {
  opacity: 1;
  transform: translateY(0);
  animation: slideFromBottom 0.3s ease-out;
}

.choose-country-modal-wrap.ant-modal-wrap.hide-modal {
  animation: slideToBottom 0.3s ease-out;
}

.choose-country-modal.ant-modal {
  margin: 0 !important;
  top: 0;
  max-width: 100% !important;
  padding: 0 !important;
}

.choose-country-modal .ant-modal-body {
  padding: 0 !important;
}

/* Transitions */

/* fade */
[class*='fade-'] {
  --duration: 0.3s;
  --delay: 0s;
  --easing: linear;
}

.fade-appear,
.fade-enter {
  opacity: 0;
}

.fade-appear-active,
.fade-enter-active,
.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
}

.fade-appear-active,
.fade-enter-active,
.fade-exit-active {
  transition: opacity var(--duration) var(--easing) var(--delay);
}
